<template>
  <v-container class="py-0">
    <v-row v-if="paiementFacture">
      <v-col
        class="body-1 text-center"
        cols="12"
      >
        <v-icon
          size="78px"
          color="success"
        >
          mdi-credit-card-outline
        </v-icon>

        <h2 class="mt-3">
          {{ $t('contract.bill.Payment_the_invoice') }} id:  [{{ paiementFacture.id }}]
        </h2>

        <h2
          v-if="paiementFacture.montant_total"
          class="mt-3"
        >
          {{ $t('Amount') }} {{ paiementFacture.montant_total.toFixed(2) }}$
        </h2>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      dense
    >
      <v-col
        cols="10"
      >
        <div id="shc-fields" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Services
  import FacturationsService from '@/services/01Cell/FacturationsService'
  // Other
  import Swal from 'sweetalert2'

  export default {
    name: 'PayfactoFacturePaiement',
    props: {
      paiementFacture: Object,
      state: String,
      form: Object,
    },
    data () {
      return {
      }
    },
    async mounted () {
      await this.initialise()
    },
    beforeDestroy () {
      const el = document.getElementById('myScript')
      if (el) { el.remove() }
    },
    methods: {
      async initialise () {
        try {
          const el = document.getElementById('myScript')
          if (el) { el.remove() }
          // // Changement de nom
          // // this.form.button.label = 'dasdasd'

          // // Charger le script Payfacto
          // const script1 = document.createElement('script')
          // script1.src = 'https://form.gateway.staging.payfacto.cloud/secure-hosted-checkout/v1/shc.js'
          // script1.async = true
          // document.head.appendChild(script1)

          // // Une fois le script chargé, initialiser le processus de paiement
          // script1.onload = () => {
          //   this.getShcToken()
          // }

          if (document.getElementById('myScript')) { return }
          const src = 'https://form.gateway.staging.payfacto.cloud/secure-hosted-checkout/v1/shc.js'
          const script = document.createElement('script')
          script.setAttribute('src', src)
          script.setAttribute('type', 'text/javascript')
          script.setAttribute('id', 'myScript')
          document.head.appendChild(script)

          // Une fois le script chargé, initialiser le processus de paiement
          script.onload = () => {
            this.getShcToken()
          }
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      getShcToken () {
        // Stop Spinner
        this.$loading.hide()
        // eslint-disable-next-line no-undef
        shc(this.form, response => {
          this.processResponse(response)
        })
      },
      async processResponse (response) {
        // Traiter la réponse du paiement ici
        if (response.success) {
          // Gérer le succès, par exemple émettre un événement ou afficher un message
          // Start Spinner
          this.$loading.show()
          try {
            // Si paiement manuelle
            if (this.state === 'paid') {
              const facture = await FacturationsService.payerFactureManuelleCard(response, this.paiementFacture.id)

              // On mets à jour la facture
              this.$emit('payer-facture-success', facture)
            } else {
              // Si c'est pour un token de carte
              this.$emit('create-paiements-inscription', response)
            }
          } catch (error) {
            if (this.state === 'paid') {
              // On mets à jour la facture
              this.$emit('payer-facture-error', this.paiementFacture.id)
            }
          }
        } else {
          // console.error('Erreur de paiement', response)
          // Swal
          Swal.fire({
            title: 'Attention !',
            // La carte de crédit finissant par ....0023 a été attribuée au contrat (221) 222-2222
            html: response.type,
            icon: 'error',
          })
          this.initialise()
          // Gérer l'erreur, par exemple afficher un message d'erreur
        }
      },
    },
  }
</script>
